import { Item } from '../model/item';

import { User } from 'firebase/auth';
import * as fs from 'firebase/firestore';
import i18next from 'i18next';
import Application from '../application/application';
import {
  DynamicOptionsListConstraintMeta,
  StaticListConstraint,
  StaticOptionsListConstraintMeta,
  ValueListConstraint,
} from '../list/filter';
import Page from '../model/page';
import Tag from '../tag/tag';
import { FirebaseTag } from '../tag/tag_service';
import ItemService, { ListParameters } from './item_service';

class OwnershipFilterValue {
  static mine = new OwnershipFilterValue('list.filter.ownership.mine');
  static all = new OwnershipFilterValue('list.filter.ownership.all');

  constructor(readonly tKey: string) {}

  get label() {
    return i18next.t(this.tKey);
  }
}

class OrderBy {
  static mostLiked = new OrderBy('list.orderBy.mostLiked');
  static newest = new OrderBy('list.orderBy.newest');

  constructor(readonly tKey: string) {}

  get label() {
    return i18next.t(this.tKey);
  }
}

abstract class BaseFirebaseItemService<
  T extends Item<T>
> extends ItemService<T> {
  readonly firebaseCollection: string;
  readonly firebaseLikesCollection: string;
  readonly likesItemIdField: string;

  constructor(
    firebaseItemsCollection: string,
    firebaseLikesCollection: string,
    likesItemIdField: string = 'item_id',
    application: Application
  ) {
    super(application);
    this.firebaseCollection = firebaseItemsCollection;
    this.firebaseLikesCollection = firebaseLikesCollection;
    this.likesItemIdField = likesItemIdField;
  }

  override getDefaultListConstraints(languages: string[], uid?: string) {
    return [
      ...(uid
        ? [
            new ValueListConstraint(
              OwnershipFilterValue.all,
              new StaticOptionsListConstraintMeta<OwnershipFilterValue>(
                (value) => {
                  if (value === OwnershipFilterValue.mine) {
                    return [fs.where('uid', '==', uid)];
                  } else if (value === OwnershipFilterValue.all) {
                    return [
                      fs.or(
                        fs.and(
                          fs.where('public', '==', true),
                          fs.where('approved', '==', true)
                        ),
                        fs.where('uid', '==', uid)
                      ),
                    ];
                  }
                  return [];
                },
                (_) => [],
                [OwnershipFilterValue.mine, OwnershipFilterValue.all],
                (value) => value.label
              )
            ),
          ]
        : [
            new StaticListConstraint(
              [
                fs.where('public', '==', true),
                fs.where('approved', '==', true),
              ],
              []
            ),
          ]),
      new ValueListConstraint(
        OrderBy.newest,
        new StaticOptionsListConstraintMeta<OrderBy>(
          (_) => [],
          (value) => {
            if (value === OrderBy.mostLiked) {
              return [
                fs.orderBy('number_of_likes', 'desc'),
                fs.orderBy('creation_timestamp', 'desc'),
              ];
            } else if (value === OrderBy.newest) {
              return [fs.orderBy('creation_timestamp', 'desc')];
            }
            return [];
          },
          [OrderBy.mostLiked, OrderBy.newest],
          (value) => value.label
        )
      ),

      new ValueListConstraint(
        undefined,
        new DynamicOptionsListConstraintMeta<Tag>(
          (value) =>
            value
              ? [
                  fs.where('tags', 'array-contains-any', [
                    (value as FirebaseTag).ref,
                  ]),
                ]
              : [],
          (_) => [],
          () => this.application.tagService.getTags(languages),
          (value) => (value ? value.getName(languages) : ''),
          undefined,
          i18next.t('filter.tag.placeholder') ?? undefined
        )
      ),
    ];
  }

  override async listItems({
    listConstraints: filters,
    limit = 23,
    afterPage,
    beforePage,
  }: ListParameters<T>): Promise<Page<T>> {
    if (afterPage && beforePage) {
      throw new Error('after and before page set');
    }
    const filterConstraints: fs.QueryFilterConstraint[] = [];
    for (const filter of filters) {
      filterConstraints.push(...filter.queryFilterConstraints);
    }
    const nonFilterConstraints: fs.QueryNonFilterConstraint[] = [];
    for (const filter of filters) {
      nonFilterConstraints.push(...filter.queryNonFilterConstraints);
    }
    if (afterPage) {
      nonFilterConstraints.push(fs.startAfter(afterPage.nextPageDocument));
    } else if (beforePage) {
      nonFilterConstraints.push(fs.endBefore(beforePage.previousPageDocument));
    }

    nonFilterConstraints.push(fs.limit(limit + 2));
    let docs = (
      await fs.getDocs(
        fs.query(
          fs.collection(fs.getFirestore(), this.firebaseCollection),
          fs.and(...filterConstraints),
          ...nonFilterConstraints
        )
      )
    ).docs;
    const hasPrev = afterPage || (beforePage && docs.length > limit);
    const hasNext = beforePage || docs.length > limit;
    if (beforePage && docs.length > limit) {
      docs = docs.slice(0, docs.length - limit);
    } else {
      docs = docs.slice(0, Math.min(limit, docs.length));
    }
    return new Page(
      await Promise.all(docs.map((m) => this.deserializeItem(m.id, m.data()))),
      hasPrev && docs.length > 0 ? docs[0] : undefined,
      hasNext && docs.length > 0 ? docs[docs.length - 1] : undefined
    );
  }

  override async getItem(id: string): Promise<T | undefined> {
    const collection = fs.collection(
      fs.getFirestore(),
      this.firebaseCollection
    );
    const reference = fs.doc(collection, id);
    const data = (await fs.getDoc(reference)).data();
    if (!data) {
      return undefined;
    }
    return await this.deserializeItem(id, data);
  }

  override async saveItem(item: T, user: User): Promise<string> {
    const collection = fs.collection(
      fs.getFirestore(),
      this.firebaseCollection
    );
    const reference = item.id
      ? fs.doc(collection, item.id)
      : fs.doc(collection);

    const serialized = this.serializeItem(item.setApproved(false), user.uid);

    if (!item.id) {
      serialized['creation_timestamp'] = fs.serverTimestamp();
      serialized['number_of_likes'] = 0;
    }
    await fs.setDoc(reference, serialized, item.id ? { merge: true } : {});
    return reference.id;
  }

  override async deleteItem(item: T) {
    if (item.id == null) {
      throw new Error('item must have been stored before (i.e. have an id)');
    }
    const reference = fs.doc(
      fs.collection(fs.getFirestore(), this.firebaseCollection),
      item.id
    );
    await fs.deleteDoc(reference);
  }

  override async likeItem(item: T, user: User): Promise<void> {
    const reference = fs.doc(
      fs.collection(fs.getFirestore(), this.firebaseLikesCollection),
      `${user.uid}_${item.id}`
    );
    const data: fs.WithFieldValue<fs.DocumentData> = {
      uid: user.uid,
    };
    data[this.likesItemIdField] = item.id;
    await fs.setDoc(reference, data);
  }

  override async unlikeItem(item: T, user: User): Promise<void> {
    const reference = fs.doc(
      fs.collection(fs.getFirestore(), this.firebaseLikesCollection),
      `${user.uid}_${item.id}`
    );
    await fs.deleteDoc(reference);
  }

  override async approveItem(item: T): Promise<void> {
    if (item.id == null) {
      throw new Error('item must have been stored before (i.e. have an id)');
    }
    const reference = fs.doc(
      fs.collection(fs.getFirestore(), this.firebaseCollection),
      item.id
    );
    await fs.setDoc(reference, { approved: true }, { merge: true });
  }

  override async unapproveAndUnpublishItem(item: T) {
    if (item.id == null) {
      throw new Error('item must have been stored before (i.e. have an id)');
    }
    const reference = fs.doc(
      fs.collection(fs.getFirestore(), this.firebaseCollection),
      item.id
    );
    await fs.setDoc(
      reference,
      { approved: false, public: false },
      { merge: true }
    );
  }

  abstract deserializeItem(
    id: string,
    data: { [fieldPath: string]: any }
  ): Promise<T>;

  abstract serializeItem(item: T, uid: string): { [fieldPath: string]: any };
}

export default BaseFirebaseItemService;
