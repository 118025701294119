import * as fs from 'firebase/firestore';

export function getTextForField(
  data: fs.DocumentData,
  field: string,
  languages: readonly string[]
): string {
  for (const language of languages) {
    const languageField = `${field}_${language.toLowerCase()}`;
    const text = data[languageField];
    if (text) {
      return text;
    }
  }
  return data[field];
}
