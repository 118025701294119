import { User } from 'firebase/auth';
import { ReactNode, Ref } from 'react';
import Application from '../application/application';
import { ItemHandle } from '../item/item_handle';
import { ListConstraint } from '../list/filter';
import { Item } from '../model/item';
import Page from '../model/page';
import { ViewDetail } from '../view/view';

export interface ListParameters<T extends Item<T>> {
  afterPage?: Page<T>;
  beforePage?: Page<T>;
  listConstraints: Array<ListConstraint>;
  limit?: number;
}

abstract class ItemService<T extends Item<T>> {
  constructor(readonly application: Application) {}

  abstract listItems({
    listConstraints,
    limit,
    afterPage,
    beforePage,
  }: ListParameters<T>): Promise<Page<T>>;

  abstract getDefaultListConstraints(
    languages: readonly string[],
    uid?: string
  ): Array<ListConstraint>;

  abstract getItem(id: string): Promise<T | undefined>;

  abstract saveItem(item: T, user: User): Promise<string>;

  abstract deleteItem(item: T): Promise<void>;

  abstract likeItem(item: T, user: User): Promise<void>;

  abstract unlikeItem(item: T, user: User): Promise<void>;

  abstract approveItem(item: T): Promise<void>;

  abstract unapproveAndUnpublishItem(item: T): Promise<void>;

  abstract getItemViewOverview(item: T): ReactNode;

  abstract getItemViewDetail(
    item: T,
    className: string,
    itemHandle: Ref<ItemHandle>
  ): ViewDetail;

  abstract getItemViewEdit(): ReactNode;
  abstract getItemViewNew(): ReactNode;
}

export default ItemService;
