import React from 'react';
import { AnalyticsSdkContext } from 'reactfire';
import { logEvent } from 'firebase/analytics';

export class GlobalErrorBoundary extends React.Component<
  React.PropsWithChildren<{}>
> {
  static contextType = AnalyticsSdkContext;
  context: React.ContextType<typeof AnalyticsSdkContext>;

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    try {
      logEvent(this.context!, 'exception', {
        description: error.message,
        cause: error.cause,
        stack: error.stack,
        name: error.name,
        source: 'GlobalErrorBoundary',
      });
    } catch (_) {}
  }

  render() {
    return this.props.children;
  }
}
