import { QueryDocumentSnapshot } from 'firebase/firestore';

class Page<T> {
  readonly items: T[];
  readonly previousPageDocument?: QueryDocumentSnapshot;
  readonly nextPageDocument?: QueryDocumentSnapshot;

  constructor(
    items: T[],
    previousPageDocument?: QueryDocumentSnapshot,
    nextPageDocument?: QueryDocumentSnapshot
  ) {
    this.items = items;
    this.previousPageDocument = previousPageDocument;
    this.nextPageDocument = nextPageDocument;
  }

  get hasPreviousPage(): boolean {
    return this.previousPageDocument !== undefined;
  }

  get hasNextPage(): boolean {
    return this.nextPageDocument !== undefined;
  }
}

export default Page;
