import { getApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

function logError(
  source: 'Window' | 'Promise',
  message: string | undefined,
  error?: Error
): void {
  try {
    const errorStack = error?.stack;
    const errorMessage = error?.message;
    logEvent(getAnalytics(getApp()), 'exception', {
      href: window.location.href,
      description: message,
      error: error,
      errorMessage: errorMessage,
      errorStack: errorStack,
      source: 'Window',
    });
  } catch (_) {}
}

export default function setupUncaughtErrorHandling(): void {
  window.addEventListener('error', (e) => {
    logError(
      'Window',
      e.message,
      e.error instanceof Error ? e.error : undefined
    );
  });

  window.addEventListener('unhandledrejection', (e) => {
    logError(
      'Promise',
      e.reason?.toString(),
      e.reason instanceof Error ? e.reason : undefined
    );
  });
}
