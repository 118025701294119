import { logEvent } from 'firebase/analytics';
import { Box, Button, CheckBox, Select } from 'grommet';
import React, {
  MouseEvent,
  Ref,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiAnticlockwiseLine,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiArrowLeftRightLine,
  RiArrowUpDownLine,
  RiBrush2Line,
  RiClockwiseLine,
  RiPaintLine,
} from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useAnalytics, useUser } from 'reactfire';
import ApplicationContext from '../common/application/context';
import {
  overviewPath,
  useEditPathParams,
  viewPathForItem,
} from '../common/routes/routes';
import { RenderType } from '../common/svg_image_renderer/svg_img_renderer';
import TagSelect from '../common/tag/tag_select';
import { CubeSvg } from '../cube/cube';
import MosaicItemService from '../item_service/mosaic_item_service';
import { Cube as CubeModel, Pattern } from '../model/cube';
import { Mosaic as MosaicModel } from '../model/mosaic';
import Mosaic from '../mosaic/mosaic';
import { Theme, availableThemes } from '../theme/theme';
import styles from './styles.module.scss';

interface EditProps {
  mosaic: MosaicModel;
}

enum Tool {
  Brush,
  Fill,
}

function Edit(props: EditProps) {
  // TODO: #28 Use the EditBuffer class similar to web_bead_pattern
  const editBufferMaxSize = 30;
  const [editBuffer, setEditBuffer] = useState([props.mosaic]);
  const [editBufferIx, setEditBufferIx] = useState(0);
  const mosaic = editBuffer[editBufferIx];

  const [cube, setCube] = useState<CubeModel>(new CubeModel(Pattern.solid1, 0));
  const [cursorCubeSize, setCursorCubeSize] = useState<number>(0);

  const [tool, setTool] = useState(Tool.Brush);
  const [mirrorHorizontally, setMirrorHorizontally] = useState(false);
  const [mirrorVertically, setMirrorVertically] = useState(false);
  const [lastTouchEventMillis, setLastTouchEventMillis] = useState<number>();
  const navigate = useNavigate();
  const { data: user } = useUser();

  const { t } = useTranslation();
  const analytics = useAnalytics();
  const application = useContext(ApplicationContext);
  const itemService = application.itemService as MosaicItemService;

  const eventData = {
    cube_pattern: cube.pattern.id,
    cube_rotation: cube.rotation,
    mosaic_size: mosaic?.size,
    mosaic_id: mosaic?.id,
  };

  const updateMosaic = useCallback(
    (fn: () => MosaicModel) => {
      const newBuffer = [...editBuffer.slice(0, editBufferIx + 1), fn()];
      let newBufferIx = editBufferIx + 1;
      if (newBuffer.length > editBufferMaxSize) {
        newBuffer.shift();
        newBufferIx--;
      }
      setEditBuffer(newBuffer);
      setEditBufferIx(newBufferIx);
    },
    [editBuffer, editBufferIx]
  );

  function onTouchEvent() {
    setLastTouchEventMillis(Date.now);
  }

  function updateCursor(event: MouseEvent) {
    if (lastTouchEventMillis && Date.now() - lastTouchEventMillis < 500) {
      return;
    }
    cursorRef.current!.setVisible(true);
    cursorRef.current!.setPosition([
      event.pageX - window.scrollX - cursorCubeSize / 2,
      event.pageY - window.scrollY - cursorCubeSize / 2,
    ]);
  }

  function hideCursor() {
    cursorRef.current!.setVisible(false);
    cursorRef.current!.setPosition(undefined);
  }

  function cancel() {
    navigate(mosaic.id ? viewPathForItem(mosaic.id) : overviewPath);
  }

  async function save() {
    const savedMosaic = await itemService.saveItem(mosaic, user!);
    navigate(viewPathForItem(savedMosaic));
  }

  function maybeRedo() {
    if (editBufferIx < editBuffer.length - 1) {
      setEditBufferIx(editBufferIx + 1);
    }
  }

  function maybeUndo() {
    if (editBufferIx > 0) {
      setEditBufferIx(editBufferIx - 1);
    }
  }

  useEffect(() => {
    const updateCursorSize = () => {
      const cursorSize =
        0.8 *
        ((mosaicRef.current?.getBoundingClientRect()?.width ?? 200) /
          mosaic.size);
      setCursorCubeSize(cursorSize);
    };
    updateCursorSize();
    window.addEventListener('resize', updateCursorSize);

    const keyHandler = (event: KeyboardEvent) => {
      if (event.code === 'KeyA' || event.code === 'KeyS') {
        const patterns = cubes.map((cube) => cube.pattern);
        let ix = patterns.indexOf(cube.pattern);
        if (event.code === 'KeyS') {
          logEvent(analytics, 'next_cube_key_down', eventData);
          ix++;
        } else {
          logEvent(analytics, 'previous_cube_key_down', eventData);
          ix--;
        }
        setCube(cubes[((ix % cubes.length) + cubes.length) % cubes.length]);
      } else if (event.code === 'KeyF') {
        logEvent(analytics, 'rotate_cube_right_key_down', eventData);
        setCube(cube.rotateRight());
      } else if (event.code === 'KeyD') {
        logEvent(analytics, 'rotate_cube_left_key_down', eventData);
        setCube(cube.rotateLeft());
      } else if ((event.key === 'z' || event.key === 'Z') && event.ctrlKey) {
        if (event.shiftKey) {
          logEvent(analytics, 'redo_key_down', eventData);
          maybeRedo();
        } else {
          logEvent(analytics, 'undo_key_down', eventData);
          maybeUndo();
        }
      }
    };

    window.addEventListener('keydown', keyHandler);
    return () => {
      window.removeEventListener('resize', updateCursorSize);
      window.removeEventListener('keydown', keyHandler);
    };
  });

  const cubes = Pattern.values
    .filter((pattern) => !pattern.equals(Pattern.empty))
    .map((pattern) => new CubeModel(pattern, cube.rotation));

  const mosaicRef = React.createRef<HTMLDivElement>();
  // use useCallback so that the mosaic can be properly memoized
  const onMosaicCubeClick = useCallback(
    (x: number, y: number) => {
      if (tool === Tool.Brush) {
        updateMosaic(() => {
          let m = mosaic.setCube(x, y, cube);
          const lix = mosaic.size - 1;
          if (mirrorHorizontally) {
            m = m.setCube(lix - x, y, cube.mirrorHorizontally());
          }
          if (mirrorVertically) {
            m = m.setCube(x, lix - y, cube.mirrorVertically());
          }
          if (mirrorHorizontally && mirrorVertically) {
            m = m.setCube(
              lix - x,
              lix - y,
              cube.mirrorVertically().mirrorHorizontally()
            );
          }
          return m;
        });
      } else if (tool === Tool.Fill) {
        updateMosaic(() => mosaic.fill(x, y, cube));
      }
    },
    [mosaic, cube, tool, mirrorVertically, mirrorHorizontally, updateMosaic]
  );

  const cursorRef = React.createRef<CursorHandle>();

  return (
    <div className={styles.container} data-testid={'c-edit'}>
      <Box>
        <Box direction={'row'} className={styles.section}>
          <Select
            size={'small'}
            options={Array.from({ length: 9 }, (v, k) => k + 2)}
            value={[mosaic.size]}
            labelKey={(size) => `${size} x ${size}`}
            onChange={(event) => {
              updateMosaic(() => MosaicModel.empty(event.value, user!.uid));
              logEvent(analytics, 'mosaic_size_change_click', {
                ...eventData,
                new_size: event.value,
              });
            }}
          />
          <Select
            size={'small'}
            options={availableThemes}
            value={mosaic.theme}
            labelKey={(theme) => theme.name}
            onChange={(event) => {
              updateMosaic(() => mosaic.setTheme(event.value));
              logEvent(analytics, 'mosaic_theme_change_click', {
                ...eventData,
                new_theme: event.value,
              });
            }}
          />
        </Box>
        <Box
          direction={'row'}
          align={'center'}
          className={`${styles.cubeSelect} ${styles.section}`}>
          {cubes.map((c) => (
            <Button
              data-testid={`tool-cube-${c.pattern.id}`}
              plain={true}
              margin={'xxsmall'}
              focusIndicator={false}
              key={c.pattern.id}
              className={`${styles.toolbarCube} ${
                c.pattern.id === cube.pattern.id ? styles.selected : ''
              }`}>
              <CubeSvg
                strokeWidth={0}
                cube={c}
                cubeSize={24}
                theme={mosaic.theme}
                onCubeClick={(_) => {
                  setCube(c);
                  logEvent(analytics, 'select_cube_click', {
                    ...eventData,
                    new_cube_pattern: c.pattern,
                  });
                }}
              />
            </Button>
          ))}
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiAnticlockwiseLine size={24} />}
            onClick={(_) => {
              setCube(cube.rotateLeft());
              logEvent(analytics, 'rotate_cube_left_click', {
                eventData,
                new_cube_rotation: cube.rotation,
              });
            }}
            focusIndicator={false}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiClockwiseLine size={24} />}
            onClick={(_) => {
              setCube(cube.rotateRight());
              logEvent(analytics, 'rotate_cube_right_click', {
                ...eventData,
                new_cube_rotation: cube.rotation,
              });
            }}
            focusIndicator={false}
          />
        </Box>
        <Box direction={'row'} align={'center'} className={styles.section}>
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiBrush2Line size={24} />}
            onClick={() => {
              setTool(Tool.Brush);
              logEvent(analytics, 'select_tool', {
                ...eventData,
                tool: 'brush',
              });
            }}
            focusIndicator={false}
            className={`${styles.tool} ${
              tool === Tool.Brush ? styles.selected : ''
            }`}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiPaintLine size={24} />}
            onClick={() => {
              setTool(Tool.Fill);
              logEvent(analytics, 'select_tool', {
                ...eventData,
                tool: 'fill',
              });
            }}
            focusIndicator={false}
            className={`${styles.tool} ${
              tool === Tool.Fill ? styles.selected : ''
            }`}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiArrowGoBackLine size={24} />}
            onClick={() => {
              maybeUndo();
              logEvent(analytics, 'undo', eventData);
            }}
            focusIndicator={false}
            disabled={editBufferIx === 0}
          />
          <Button
            plain={true}
            margin={'xxsmall'}
            icon={<RiArrowGoForwardLine size={24} />}
            onClick={() => {
              maybeRedo();
              logEvent(analytics, 'redo', eventData);
            }}
            focusIndicator={false}
            disabled={editBufferIx === editBuffer.length - 1}
          />
          <Box direction={'row'} className={styles.modifiers}>
            <Button
              plain={true}
              margin={'xxsmall'}
              icon={<RiArrowLeftRightLine size={24} />}
              onClick={() => {
                setMirrorHorizontally(!mirrorHorizontally);
                logEvent(analytics, 'mirror_horizontally_click', {
                  ...eventData,
                  mirror_horizontally: !mirrorHorizontally,
                });
              }}
              className={`${styles.tool} ${
                mirrorHorizontally ? styles.selected : ''
              }`}
            />
            <Button
              plain={true}
              margin={'xxsmall'}
              icon={<RiArrowUpDownLine size={24} />}
              onClick={() => {
                setMirrorVertically(!mirrorVertically);
                logEvent(analytics, 'mirror_vertically_click', {
                  ...eventData,
                  mirror_vertically: !mirrorVertically,
                });
              }}
              className={`${styles.tool} ${
                mirrorVertically ? styles.selected : ''
              }`}
            />
          </Box>
        </Box>
        {mosaic && (
          <>
            <div
              ref={mosaicRef}
              className={`${styles.mosaic} ${styles.section}`}
              onMouseEnter={updateCursor}
              onMouseMove={updateCursor}
              onMouseLeave={hideCursor}
              onTouchStart={onTouchEvent}
              onTouchEnd={onTouchEvent}>
              <Mosaic
                mosaic={mosaic}
                onCubeClick={onMosaicCubeClick}
                render={RenderType.svg}
              />
            </div>
            <Cursor
              cube={cube}
              cursorCubeSize={cursorCubeSize}
              theme={mosaic.theme}
              ref={cursorRef}
            />
          </>
        )}
        <Box className={styles.section}>
          <CheckBox
            label={t('editor.public.checkbox')}
            checked={mosaic.public}
            onChange={(event) =>
              updateMosaic(() => mosaic.setPublic(event.target.checked))
            }
          />
        </Box>
        <Box className={styles.section}>
          <TagSelect
            values={mosaic.tags}
            onChange={(v) => updateMosaic(() => mosaic.setTags(v))}></TagSelect>
        </Box>
        <Box
          direction={'row'}
          align={'start'}
          className={styles.section}
          margin={{ bottom: 'small' }}>
          <Button
            data-testid={'save-button'}
            primary={true}
            margin={{ right: 'small' }}
            label={t('editor.save')}
            onClick={() => {
              logEvent(analytics, 'save_click', eventData);
              return save();
            }}
            disabled={!mosaic.isComplete}
          />
          <Button
            label={t('editor.cancel')}
            onClick={() => {
              logEvent(analytics, 'cancel_click', eventData);
              cancel();
            }}
          />
        </Box>
      </Box>
    </div>
  );
}

interface CursorHandle {
  setVisible: (visible: boolean) => void;
  setPosition: (position: [number, number] | undefined) => void;
}

interface CursorProps {
  cube: CubeModel;
  cursorCubeSize: number;
  theme: Theme;
}

function _Cursor(
  { cube, cursorCubeSize, theme }: CursorProps,
  ref: Ref<CursorHandle>
) {
  const [cursorPosition, setCursorPosition] = useState<
    [number, number] | undefined
  >();
  const [cursorVisible, setCursorVisible] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    setVisible: (visible: boolean) => {
      setCursorVisible(visible);
    },
    setPosition: (position: [number, number] | undefined) => {
      setCursorPosition(position);
    },
  }));

  return (
    <>
      {cursorVisible && cursorPosition && (
        <div
          className={styles.cursor}
          style={{
            width: cursorCubeSize,
            height: cursorCubeSize,
            left: cursorPosition[0],
            top: cursorPosition[1],
          }}>
          <CubeSvg
            cube={cube}
            theme={theme}
            cubeSize={cursorCubeSize}
            strokeWidth={0}
          />
        </div>
      )}
    </>
  );
}

const Cursor = forwardRef(_Cursor);

export function EditMosaic() {
  const pathParams = useEditPathParams();
  const [mosaic, setMosaic] = useState<MosaicModel | undefined>(undefined);
  const itemService = useContext(ApplicationContext).itemService;
  const { data: user } = useUser();

  useEffect(() => {
    itemService.getItem(pathParams.id!).then((item) => {
      if (pathParams.action === 'copy') {
        item = item?.copyForCurrentUser(user!.uid);
      }
      setMosaic(item as MosaicModel);
    });
  }, [pathParams.id, itemService, pathParams.action, user]);
  return <>{mosaic && <Edit mosaic={mosaic} />}</>;
}

export function NewMosaic() {
  const { data: user } = useUser();

  return <Edit mosaic={MosaicModel.empty(8, user!.uid)} />;
}
