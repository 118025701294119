import * as React from 'react';
import { useIsAdmin } from '../firebase/user_hooks';

export interface AdminCheckProps {
  fallback: React.ReactNode;
  children: React.ReactNode;
}

function AdminCheck({ fallback, children }: AdminCheckProps): JSX.Element {
  const isAdmin = useIsAdmin();

  if (isAdmin === true) {
    return <>{children}</>;
  } else if (isAdmin === false) {
    return <>{fallback}</>;
  } else {
    return <></>;
  }
}

export default AdminCheck;
