import { Box, Button, Select } from 'grommet';
import i18next from 'i18next';
import {
  Dispatch,
  SetStateAction,
  createRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RiAddLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'reactfire';
import ApplicationContext from '../application/context';
import {
  DynamicOptionsListConstraintMeta,
  ListConstraint,
  StaticOptionsListConstraintMeta,
  ValueListConstraint,
} from '../list/filter';
import { Item } from '../model/item';
import Page from '../model/page';
import { newPath } from '../routes/routes';
import OverviewCard from './overview_card';
import styles from './styles.module.scss';

interface FilterSelectorProps {
  valueListConstraint: ValueListConstraint<any>;
  setListConstraints: Dispatch<SetStateAction<ListConstraint[]>>;
}

function FilterSelector({
  valueListConstraint,
  setListConstraints,
}: FilterSelectorProps) {
  if (
    !(
      valueListConstraint.filterMeta instanceof StaticOptionsListConstraintMeta
    ) &&
    !(
      valueListConstraint.filterMeta instanceof DynamicOptionsListConstraintMeta
    )
  ) {
    throw new Error('Unknown filter type ' + typeof valueListConstraint);
  }
  const defaultOptions =
    valueListConstraint.filterMeta instanceof StaticOptionsListConstraintMeta
      ? valueListConstraint.filterMeta.options
      : undefined;
  const [options, setOptions] = useState(defaultOptions);
  useEffect(() => {
    if (
      valueListConstraint.filterMeta instanceof DynamicOptionsListConstraintMeta
    ) {
      valueListConstraint.filterMeta
        .options()
        .then((options) => setOptions(options));
    }
  }, [valueListConstraint.filterMeta]);

  return (
    options !== undefined && (
      <Select
        size={'small'}
        clear={
          valueListConstraint.filterMeta.placeholderText
            ? { label: i18next.t('filter.clear') ?? undefined }
            : false
        }
        placeholder={valueListConstraint.filterMeta.placeholderText}
        options={options!}
        labelKey={valueListConstraint.filterMeta.itemTextRenderer}
        value={valueListConstraint.value}
        onChange={({ option: newValue }) => {
          setListConstraints((filters) => {
            const ix = filters.indexOf(valueListConstraint);
            return [
              ...filters.slice(0, ix),
              valueListConstraint.updateValue(newValue),
              ...filters.slice(ix + 1),
            ];
          });
        }}
        children={valueListConstraint.filterMeta.itemRenderer}
      />
    )
  );
}

let stickyListConstraints: ListConstraint[] | undefined = undefined;

function Overview() {
  const [items, setItems] = useState<Item<any>[]>([]);
  const [lastPage, setLastPage] = useState<Page<Item<any>>>();
  const { data: user } = useUser();
  const navigate = useNavigate();
  const itemService = useContext(ApplicationContext).itemService;
  const { t, i18n } = useTranslation();

  const [listConstraints, setListConstraints] = useState(
    stickyListConstraints !== undefined
      ? stickyListConstraints
      : itemService.getDefaultListConstraints(i18n.languages, user?.uid)
  );

  stickyListConstraints = listConstraints;

  useEffect(() => {
    setListConstraints((constraints) => {
      const isSticky = constraints === stickyListConstraints;
      return !isSticky
        ? itemService.getDefaultListConstraints(i18n.languages, user?.uid)
        : constraints;
    });
    stickyListConstraints = undefined;
  }, [itemService, i18n.languages, user?.uid]);

  useEffect(() => {
    stickyListConstraints = listConstraints;
  }, [listConstraints]);

  useEffect(() => {
    setItems([]);
    setLastPage(undefined);
  }, [listConstraints]);

  async function fetchPage() {
    if (lastPage && !lastPage!.hasNextPage) {
      return;
    }
    const page = await itemService.listItems({
      listConstraints: listConstraints,
      limit: 12,
      afterPage: lastPage,
    });
    setLastPage(page);
    setItems([...(items ?? []), ...page.items]);
  }

  const marker = createRef<HTMLDivElement>();
  useEffect(() => {
    const el = marker.current!;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) fetchPage();
      },
      { rootMargin: '0% 0% 25% 0%' }
    );
    observer.observe(el);
    return () => observer.unobserve(el);
  });

  return (
    <Box align={'center'} data-testid={'c-overview'}>
      <Box
        direction="row"
        style={{ minHeight: 'auto' }}
        gap={'small'}
        pad={'small'}>
        {listConstraints
          .filter((c) => c instanceof ValueListConstraint)
          .map((c) => (
            <FilterSelector
              valueListConstraint={c as ValueListConstraint<any>}
              setListConstraints={setListConstraints}
              key={c.renderKey}></FilterSelector>
          ))}
      </Box>

      <div className={styles.grid}>
        {items.length > 0 &&
          items.map((item) => {
            return (
              <div key={item.id} className={styles.card}>
                <OverviewCard item={item}>
                  {itemService.getItemViewOverview(item)}
                </OverviewCard>
              </div>
            );
          })}
        {items.length === 0 && lastPage && <div>{t('overview.noResults')}</div>}

        <div ref={marker} style={{ minWidth: '1px', minHeight: '1px' }} />
      </div>

      <Button
        data-testid={'create-button'}
        plain
        color={'brand'}
        primary={true}
        style={{ padding: 4 }}
        className={styles.floatingButton}
        icon={
          <RiAddLine
            size={48}
            style={{ maxWidth: '10vw', maxHeight: '10vw' }}
          />
        }
        onClick={() => navigate(newPath)}
      />
    </Box>
  );
}

export default Overview;
