import * as fs from 'firebase/firestore';
import { ReactNode } from 'react';

let renderKeyCounter: number = 0;

export interface ListConstraint {
  get queryFilterConstraints(): Array<fs.QueryFilterConstraint>;
  get queryNonFilterConstraints(): Array<fs.QueryNonFilterConstraint>;
  get renderKey(): number;
}

export class StaticListConstraint implements ListConstraint {
  readonly renderKey: number;
  constructor(
    readonly queryFilterConstraints: Array<fs.QueryFilterConstraint>,
    readonly queryNonFilterConstraints: Array<fs.QueryNonFilterConstraint>
  ) {
    this.renderKey = renderKeyCounter++;
  }
}

export interface ListConstraintMeta<T> {
  queryFilterConstraints(value: T): Array<fs.QueryFilterConstraint>;
  queryNonFilterConstraints(value: T): Array<fs.QueryNonFilterConstraint>;
}

export class ValueListConstraint<T> implements ListConstraint {
  readonly renderKey: number;
  constructor(readonly value: T, readonly filterMeta: ListConstraintMeta<T>) {
    this.renderKey = renderKeyCounter++;
  }

  get queryFilterConstraints(): Array<fs.QueryFilterConstraint> {
    return this.filterMeta.queryFilterConstraints(this.value);
  }

  get queryNonFilterConstraints(): Array<fs.QueryNonFilterConstraint> {
    return this.filterMeta.queryNonFilterConstraints(this.value);
  }

  updateValue(newValue: T) {
    return new ValueListConstraint(newValue, this.filterMeta);
  }
}

class BaseOptionsListConstraintMeta<T> implements ListConstraintMeta<T> {
  constructor(
    readonly getQueryFilterConstraintsFn: (
      item: T
    ) => Array<fs.QueryFilterConstraint>,
    readonly getQueryNonFilterConstraintsFn: (
      item: T
    ) => Array<fs.QueryNonFilterConstraint>,
    readonly itemTextRenderer?: (item: T) => string,
    readonly itemRenderer?: (item: T) => ReactNode,
    readonly placeholderText?: string
  ) {}

  queryFilterConstraints(value: T): fs.QueryFilterConstraint[] {
    return this.getQueryFilterConstraintsFn(value);
  }

  queryNonFilterConstraints(value: T): fs.QueryNonFilterConstraint[] {
    return this.getQueryNonFilterConstraintsFn(value);
  }
}

export class StaticOptionsListConstraintMeta<
  T
> extends BaseOptionsListConstraintMeta<T> {
  constructor(
    getQueryFilterConstraintsFn: (item: T) => Array<fs.QueryFilterConstraint>,
    getQueryNonFilterConstraintsFn: (
      item: T
    ) => Array<fs.QueryNonFilterConstraint>,
    readonly options: Array<T>,
    itemTextRenderer?: (item: T) => string,
    itemRenderer?: (item: T) => ReactNode,
    placeholderText?: string
  ) {
    super(
      getQueryFilterConstraintsFn,
      getQueryNonFilterConstraintsFn,
      itemTextRenderer,
      itemRenderer,
      placeholderText
    );
  }
}

export class DynamicOptionsListConstraintMeta<
  T
> extends BaseOptionsListConstraintMeta<T> {
  constructor(
    getQueryFilterConstraintsFn: (item: T) => Array<fs.QueryFilterConstraint>,
    getQueryNonFilterConstraintsFn: (
      item: T
    ) => Array<fs.QueryNonFilterConstraint>,
    readonly options: () => Promise<Array<T>>,
    itemTextRenderer?: (item: T) => string,
    itemRenderer?: (item: T) => ReactNode,
    placeholderText?: string
  ) {
    super(
      getQueryFilterConstraintsFn,
      getQueryNonFilterConstraintsFn,
      itemTextRenderer,
      itemRenderer,
      placeholderText
    );
  }
}
