import * as fs from 'firebase/firestore';
import { Box, Button } from 'grommet';
import { useCallback, useContext, useEffect, useState } from 'react';
import ApplicationContext from '../application/context';
import { StaticListConstraint } from '../list/filter';
import { Item } from '../model/item';
import TagsList from '../tag/tags_list';
import styles from './styles.module.scss';

function ApprovalList() {
  const [items, setItems] = useState<Item<any>[]>([]);
  const itemService = useContext(ApplicationContext).itemService;

  const loadPage = useCallback(async () => {
    const page = await itemService.listItems({
      listConstraints: [
        new StaticListConstraint(
          [fs.where('approved', '==', false), fs.where('public', '==', true)],
          []
        ),
      ],
      limit: 10,
    });
    setItems(page.items);
  }, [itemService]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  async function approve(item: Item<any>) {
    await itemService.approveItem(item);
    await loadPage();
  }

  async function unapproveAndUnpublish(item: Item<any>) {
    await itemService.unapproveAndUnpublishItem(item);
    await loadPage();
  }

  if (items.length === 0) {
    return <Box margin={'large'}>No items to approve</Box>;
  }

  return (
    <Box direction={'column'} data-testid={'c-approval-list'}>
      {items!.map((item) => {
        return (
          <div
            key={item.id}
            style={{ margin: 16 }}
            data-testid={'c-approval-item'}>
            <div key={item.id} className={styles.item}>
              {itemService.getItemViewOverview(item)}
            </div>
            <Box margin={{ top: 'small' }}>
              <TagsList tags={item.tags}></TagsList>
            </Box>
            <Box
              direction={'row'}
              justify={'between'}
              margin={{ top: 'small' }}>
              <Button
                label={'Approve'}
                onClick={() => approve(item)}
                data-testid={'c-approve-button'}
              />
              <Button
                label={'Unpublish'}
                onClick={() => unapproveAndUnpublish(item)}
              />
            </Box>
          </div>
        );
      })}
    </Box>
  );
}

export default ApprovalList;
