import { Pattern } from '../model/cube';

const gray = '#B4B4B4';
const white = '#FFFFFF';
const red = '#FF0000';
const blue = '#0000FF';
const yellow = '#FFFF00';

const darkGreen = '#0A7800';
const orange = '#FF8200';

const darkBlue = '#103C9F';
const yellow2 = '#FFFF64';
const neonGreen = '#6EFF6E';

export abstract class Theme {
  // A string that uniquely describes the theme
  readonly id: string;
  readonly name: string;

  protected constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  abstract getSolidColor(pattern: Pattern): string;

  getDiagColorTopLeft(pattern: Pattern) {
    return pattern.equals(Pattern.diag1)
      ? this.getSolidColor(Pattern.solid1)
      : this.getSolidColor(Pattern.solid3);
  }

  getDiagColorBottomRight(pattern: Pattern) {
    return pattern.equals(Pattern.diag1)
      ? this.getSolidColor(Pattern.solid2)
      : this.getSolidColor(Pattern.solid4);
  }

  toString() {
    return this.name;
  }
}

class DarkBlueGreenTheme extends Theme {
  constructor() {
    // TODO i18n
    super('Dark blue & green', 'Dark blue & green');
  }

  getSolidColor(pattern: Pattern) {
    switch (pattern) {
      case Pattern.empty:
        return gray;
      case Pattern.solid1:
        return darkBlue;
      case Pattern.solid2:
        return neonGreen;
      case Pattern.solid3:
        return yellow2;
      case Pattern.solid4:
        return red;
      default:
        throw new Error(pattern.toString());
    }
  }
}

class GreenOrangeTheme extends Theme {
  constructor() {
    // TODO i18n
    super('Green & orange', 'Green & orange');
  }

  getSolidColor(pattern: Pattern) {
    switch (pattern) {
      case Pattern.empty:
        return gray;
      case Pattern.solid1:
        return darkGreen;
      case Pattern.solid2:
        return orange;
      case Pattern.solid3:
        return blue;
      case Pattern.solid4:
        return white;
      default:
        throw new Error(pattern.toString());
    }
  }
}

class ClassicTheme extends Theme {
  constructor() {
    // TODO i18n
    super('Classic', 'Classic');
  }

  getSolidColor(pattern: Pattern) {
    switch (pattern) {
      case Pattern.empty:
        return gray;
      case Pattern.solid1:
        return red;
      case Pattern.solid2:
        return white;
      case Pattern.solid3:
        return yellow;
      case Pattern.solid4:
        return blue;
      default:
        throw new Error(pattern.toString());
    }
  }
}

const classicTheme = new ClassicTheme();
const greenOrangeTheme = new GreenOrangeTheme();
const darkBlueGreenTheme = new DarkBlueGreenTheme();

export const defaultTheme = classicTheme;
export const availableThemes = [
  classicTheme,
  greenOrangeTheme,
  darkBlueGreenTheme,
];

export function findThemeById(id: string) {
  return availableThemes.find((t) => t.id === id) ?? defaultTheme;
}
