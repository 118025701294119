import * as fs from 'firebase/firestore';
import { getTextForField } from '../i18n/firebase_util';
import Tag from './tag';

export default interface TagService {
  getTags(languages?: readonly string[]): Promise<Tag[]>;
  searchTags(search: string, languages: readonly string[]): Promise<Tag[]>;
  getTag(id: string, languages: readonly string[]): Promise<Tag | undefined>;
  reset(): void;
}

export class FirebaseTag implements Tag {
  constructor(
    readonly id: string,
    readonly documentData: fs.DocumentData,
    readonly ref: fs.DocumentReference
  ) {}

  getName(languages: readonly string[]): string {
    const name = getTextForField(this.documentData, 'name', languages);
    return name ?? this.id;
  }
}

export function sortTags(
  tags: Iterable<Tag>,
  languages: readonly string[]
): Tag[] {
  return Array.from(tags).sort((a, b) =>
    a.getName(languages).localeCompare(b.getName(languages), languages[0])
  );
}

export class FirebaseTagService implements TagService {
  static tagCollection = 'tag';

  private tags: Map<string, Tag> | undefined;

  async getTags(languages?: string[]): Promise<Tag[]> {
    await this.loadTags();
    if (languages === undefined) {
      return Array.from(this.tags!.values()).sort((a, b) =>
        a.id.localeCompare(b.id)
      );
    } else {
      return sortTags(this.tags!.values(), languages);
    }
  }

  async searchTags(search: string, languages: string[]): Promise<Tag[]> {
    await this.loadTags();
    const normalizedSearch = search.toLocaleLowerCase(languages[0]);
    return Array.from(this.tags!.values()).filter((tag) =>
      tag
        .getName(languages)
        .toLocaleLowerCase(languages[0])
        .includes(normalizedSearch)
    );
  }

  async getTag(id: string): Promise<Tag | undefined> {
    await this.loadTags();
    return this.tags!.get(id);
  }

  reset(): void {
    this.tags = undefined;
  }

  async updateTag(
    tag: FirebaseTag,
    documentData: fs.DocumentData
  ): Promise<void> {
    await fs.updateDoc(tag.ref, documentData);
    this.reset();
  }

  async deleteTag(tag: FirebaseTag): Promise<void> {
    await fs.deleteDoc(tag.ref);
    this.reset();
  }

  async createTag(id: string) {
    await fs.setDoc(
      fs.doc(fs.getFirestore(), FirebaseTagService.tagCollection, id),
      {}
    );
    this.reset();
  }

  private async loadTags(): Promise<void> {
    if (this.tags !== undefined) {
      return;
    }
    let tagDocs = await fs
      .getDocs(
        fs.query(
          fs.collection(fs.getFirestore(), FirebaseTagService.tagCollection)
        )
      )
      .then((docsQuery) => docsQuery.docs);
    const tags = tagDocs.map((doc) => {
      return new FirebaseTag(doc.id, doc.data(), doc.ref);
    });

    this.tags = tags.reduce((map, tag) => {
      map.set(tag.id, tag);
      return map;
    }, new Map());
  }

  static toTagDocumentReferences(
    tags: Set<Tag>
  ): fs.DocumentReference<fs.DocumentData>[] {
    return Array.from(tags).map((tag) => (tag as FirebaseTag).ref);
  }

  async fromTagDocumentReferences(
    tags: fs.DocumentReference<fs.DocumentData>[] | undefined
  ): Promise<Set<Tag>> {
    if (!tags) {
      return new Set();
    }
    return new Set(
      (await Promise.all(tags.map((tag) => this.getTag(tag.id))))
        .filter((t) => t !== undefined)
        .map((tag) => tag!)
    );
  }
}
