import { Box, Button, Markdown, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { useUser } from 'reactfire';
import { useContext, useEffect, useState } from 'react';
import Notification from './notification';
import { RiCloseLine } from 'react-icons/ri';
import ApplicationContext from '../application/context';

export default function NotificationBar() {
  const { i18n } = useTranslation();
  const { data: user } = useUser();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const notificationSevice = useContext(ApplicationContext).notificationService;

  useEffect(() => {
    notificationSevice
      .getNotifications(user, i18n.languages)
      .then((notifications) => {
        setNotifications(notifications);
      });
  }, [user, i18n.languages, notificationSevice]);

  if (notifications?.length === 0) {
    return <></>;
  }
  const notification = notifications[0];
  return (
    <Box
      background={'brand'}
      height={'xsmall'}
      style={{ position: 'relative', maxHeight: 'xsmall' }}>
      <Button
        pad={'none'}
        style={{ position: 'absolute', right: 16, top: 4 }}
        icon={<RiCloseLine size={24} />}
        alignSelf={'end'}
        onClick={() => {
          notificationSevice
            .dismissNotification(user, notification)
            .then(() => {
              setNotifications(notifications.slice(1));
            });
        }}
      />
      <Box
        overflow={'auto'}
        pad={{ left: '8px', top: '8px', bottom: '8px', right: '40px' }}>
        <Text weight={'bold'}>{notification.title}</Text>
        {notification.message && <Markdown>{notification.message}</Markdown>}
      </Box>
    </Box>
  );
}
