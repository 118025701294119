import { User } from 'firebase/auth';
import * as fs from 'firebase/firestore';
import { getTextForField } from '../i18n/firebase_util';
import Notification from './notification';

export default interface NotificationService {
  getNotifications(
    user: User | null,
    languages: readonly string[]
  ): Promise<Array<Notification>>;
  dismissNotification(
    user: User | null,
    notification: Notification
  ): Promise<void>;
}

export class FirebaseNotificationService implements NotificationService {
  static notificationCollection = 'notification';
  static notificationDismissalCollection = 'notification_dismissal';

  unauthenticatedDimsissals: string[] = [];

  constructor(readonly site: string) {}

  async getNotifications(
    user: User | null,
    languages: string[]
  ): Promise<Array<Notification>> {
    let notificationDocsPromise = fs
      .getDocs(
        fs.query(
          fs.collection(
            fs.getFirestore(),
            FirebaseNotificationService.notificationCollection
          ),
          fs.and(
            fs.or(
              fs.where('site', '==', this.site),
              fs.where('site', '==', 'all')
            ),
            fs.where('published', '==', true)
          ),
          fs.orderBy('publication_timestamp', 'desc')
        )
      )
      .then((docsQuery) => docsQuery.docs);

    let dismissedNotificationIdsPromise: Promise<string[]> = Promise.resolve(
      this.unauthenticatedDimsissals
    );
    if (user) {
      dismissedNotificationIdsPromise = fs
        .getDocs(
          fs.query(
            fs.collection(
              fs.getFirestore(),
              FirebaseNotificationService.notificationDismissalCollection
            ),
            fs.where('uid', '==', user.uid)
          )
        )
        .then((docsQuery) =>
          docsQuery.docs.map((doc) => doc.data().notification_id)
        );
    }
    const [notificationDocs, dismissedNotificationIds] = await Promise.all([
      notificationDocsPromise,
      dismissedNotificationIdsPromise,
    ]);

    return notificationDocs
      .filter((doc) => !dismissedNotificationIds.includes(doc.id))
      .map((doc) => {
        const data = doc.data();
        return new Notification(
          doc.id,
          getTextForField(data, 'title', languages),
          getTextForField(data, 'message', languages)
        );
      });
  }

  async dismissNotification(user: User | null, notification: Notification) {
    if (user) {
      const reference = fs.doc(
        fs.collection(
          fs.getFirestore(),
          FirebaseNotificationService.notificationDismissalCollection
        ),
        `${user.uid}_${notification.id}`
      );
      const data: fs.WithFieldValue<fs.DocumentData> = {
        uid: user.uid,
        notification_id: notification.id,
      };

      await fs.setDoc(reference, data);
    } else {
      this.unauthenticatedDimsissals.push(notification.id);
    }
  }
}
