import * as fs from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from 'react';
import { useFirestore, useUser } from 'reactfire';
import Experiment from '../experiments/experiment';
import UserInfo, { anonymousUserInfo } from '../user/user_info';

const UserContext = createContext<UserInfo | undefined>(undefined);

export function UserContextProviders({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data: user } = useUser();
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  const firestore = useFirestore();

  useEffect(() => {
    async function updateUserInfo() {
      if (!user) {
        setUserInfo(anonymousUserInfo);
        return;
      }
      const ref = fs.doc(fs.collection(firestore, 'user'), user.uid);
      const snap = await fs.getDoc(ref);
      if (!snap.exists()) {
        setUserInfo(anonymousUserInfo);
        return;
      }
      setUserInfo(new FirebaseUserInfo(snap.data()!));
    }

    updateUserInfo();
  }, [user, firestore]);
  return (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  );
}

export function useIsAdmin(): boolean | undefined {
  const userInfo = useUserInfo();
  return userInfo?.isAdmin;
}

export function useUserInfo(): UserInfo | undefined {
  const userInfo = useContext(UserContext);
  return userInfo;
}

class FirebaseUserInfo implements UserInfo {
  constructor(private readonly data: fs.DocumentData) {}

  get isAdmin(): boolean {
    return this.data['isAdmin'] ?? false;
  }

  isInExperiment(experiment: Experiment): boolean {
    const experiments = this.data['experiments'];
    return experiments && experiments.includes(experiment.name);
  }
}
