import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { overviewPath, useRedirectQueryParameter } from '../routes/routes';
import StyledFirebaseAuth from './styled_firebase_auth';
import { useAuth } from 'reactfire';
import { Box, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

function createUiConfig(successUrl: string) {
  return {
    signInFlow: 'redirect',
    signInSuccessUrl: successUrl,
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
    ],
    credentialHelper: 'none',
  };
}

function Login() {
  const uiConfig = createUiConfig(useRedirectQueryParameter() ?? overviewPath);
  const auth = useAuth();
  const [t] = useTranslation();
  return (
    <Box
      direction={'column'}
      data-testid={'c-login'}
      justify="center"
      width={'50%'}>
      <Heading level={2}>{t('login.title')}</Heading>
      <Text>{t('login.introduction')}</Text>
      <Box
        direction={'row'}
        justify={'center'}
        background={'brand'}
        elevation="large"
        margin={{ top: 'medium', bottom: 'medium' }}
        pad={'medium'}>
        {t('login.info')}
      </Box>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </Box>
  );
}

export default Login;
