import React, { memo, useState } from 'react';
import { Cube as CubeModel } from '../model/cube';
import { v4 as uuidv4 } from 'uuid';
import { svgStyles } from '../svg/styles';
import { Theme } from '../theme/theme';

interface CubeProps {
  cube: CubeModel;
  cubeSize: number;
  strokeWidth: number;
  svgIdPrefix: string;

  onCubeClick?: (x: number, y: number) => void;
  x?: number;
  y?: number;
}

export function Cube({
  cube,
  cubeSize,
  strokeWidth,
  svgIdPrefix,
  onCubeClick = undefined,
  x = 0,
  y = 0,
}: CubeProps) {
  return (
    <rect
      onClick={(_) => onCubeClick && onCubeClick(x, y)}
      x={strokeWidth / 2 + x * cubeSize}
      y={strokeWidth / 2 + y * cubeSize}
      width={cubeSize}
      height={cubeSize}
      fill={'url(#' + svgIdPrefix + cube.pattern.id + ')'}
      strokeWidth={strokeWidth}
      stroke="black"
      transform={
        'rotate(' +
        (cube.rotation * 90).toString() +
        ',' +
        (strokeWidth / 2 + (x + 0.5) * cubeSize).toString() +
        ',' +
        (strokeWidth / 2 + (y + 0.5) * cubeSize).toString() +
        ')'
      }
    />
  );
}

interface CubeSvgProps {
  cube: CubeModel;
  cubeSize: number;
  strokeWidth: number;
  theme: Theme;

  onCubeClick?: (x: number, y: number) => void;
  x?: number;
  y?: number;
}

function _CubeSvg({
  cube,
  cubeSize,
  strokeWidth,
  theme,
  x = 0,
  y = 0,
  onCubeClick = undefined,
}: CubeSvgProps) {
  // Set the id prefix once to a random value
  const [svgIdPrefix] = useState(uuidv4());
  return (
    <svg
      viewBox={`0 0 ${cubeSize} ${cubeSize}`}
      style={{ width: '100%', height: '100%' }}>
      {svgStyles(theme, svgIdPrefix)}
      <Cube
        strokeWidth={strokeWidth}
        cubeSize={cubeSize}
        svgIdPrefix={svgIdPrefix}
        cube={cube}
        x={x}
        y={y}
        onCubeClick={onCubeClick}
      />
    </svg>
  );
}

export const CubeSvg = memo(_CubeSvg);
