import ItemService from '../item_service/item_service';
import { Item } from '../model/item';
import NotificationService from '../notification/notification_service';
import { RenderType } from '../svg_image_renderer/svg_img_renderer';
import TagService from '../tag/tag_service';

interface ApplicationParameters {
  languages?: string[];
  defaultRenderType?: RenderType;
  additionalAuthDomains?: Set<string>;
  itemService: (application: Application) => ItemService<Item<any>>;
  notificationService: (application: Application) => NotificationService;
  tagService: (application: Application) => TagService;
}

export default class Application {
  readonly defaultRenderType: RenderType;
  readonly additionalAuthDomains: Set<string>;
  readonly itemServiceFactory: (application: Application) => ItemService<any>;
  private _itemService?: ItemService<any>;
  readonly notificationServiceFactory: (
    application: Application
  ) => NotificationService;
  private _notificationService?: NotificationService;
  readonly tagServiceFactory: (application: Application) => TagService;
  private _tagService?: TagService;
  readonly languages: string[];

  constructor({
    languages = ['en'],
    defaultRenderType = RenderType.svg,
    additionalAuthDomains = new Set(),
    itemService,
    notificationService,
    tagService,
  }: ApplicationParameters) {
    this.languages = languages;
    this.defaultRenderType = defaultRenderType;
    this.additionalAuthDomains = additionalAuthDomains;
    this.itemServiceFactory = itemService;
    this.notificationServiceFactory = notificationService;
    this.tagServiceFactory = tagService;
  }

  get itemService() {
    this._itemService ??= this.itemServiceFactory(this);
    return this._itemService;
  }

  get tagService() {
    this._tagService ??= this.tagServiceFactory(this);
    return this._tagService;
  }

  get notificationService() {
    this._notificationService ??= this.notificationServiceFactory(this);
    return this._notificationService;
  }
}
