import { Pattern } from '../model/cube';
import React from 'react';
import { Theme } from '../theme/theme';

export function gradientsForSolidPatterns(theme: Theme, svgIdPrefix: string) {
  return Pattern.solidPatterns.map((p) => (
    <linearGradient id={svgIdPrefix + p.id} key={p.id}>
      <stop offset="0%" stopColor={theme.getSolidColor(p)} />
    </linearGradient>
  ));
}

export function gradientsForDiagPatterns(theme: Theme, svgIdPrefix: string) {
  return Pattern.diagPatterns.map((p) => (
    <linearGradient
      id={svgIdPrefix + p.id}
      x1="0%"
      x2="100%"
      y1="0%"
      key={p.id}
      y2="100%">
      <stop offset="0%" stopColor={theme.getDiagColorTopLeft(p)} />
      <stop offset="50%" stopColor={theme.getDiagColorTopLeft(p)} />
      <stop offset="50.1%" stopColor={theme.getDiagColorBottomRight(p)} />
      <stop offset="100%" stopColor={theme.getDiagColorBottomRight(p)} />
    </linearGradient>
  ));
}

export function svgStyles(theme: Theme, svgIdPrefix: string) {
  return (
    <>
      {gradientsForSolidPatterns(theme, svgIdPrefix)}
      {gradientsForDiagPatterns(theme, svgIdPrefix)}
    </>
  );
}
