import { logEvent } from 'firebase/analytics';
import { User } from 'firebase/auth';
import {
  Anchor,
  Box,
  DropButton,
  Header,
  Layer,
  Menu,
  ResponsiveContext,
  Text,
} from 'grommet';
import { FormDown, Menu as MenuIcon } from 'grommet-icons/icons';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiQuestionLine } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAnalytics, useAuth, useUser } from 'reactfire';
import { useIsAdmin } from '../firebase/user_hooks';
import NotificationBar from '../notification/notification_bar';
import {
  approvalPath,
  impressumPath,
  loginPathForRedirect,
  newPath,
  overviewPath,
  tagsPath,
} from '../routes/routes';
import styles from './styles.module.scss';

function ifLoggedIn(user: User | null, entry: MenuEntry) {
  return user ? [entry] : [];
}

function ifLoggedOut(user: User | null, entry: MenuEntry) {
  return !user ? [entry] : [];
}

function ifAdmin(isAdmin: boolean, entry: MenuEntry) {
  return isAdmin ? [entry] : [];
}

interface MenuEntry {
  label: string;
  onClick: () => void;
  rel?: string;
}

interface ApplicationHeaderProps {
  help: React.ReactElement;
}

function ApplicationHeader({ help }: ApplicationHeaderProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useUser();
  const isAdmin = useIsAdmin();
  const auth = useAuth();
  const analytics = useAnalytics();

  const [showHelp, setShowHelp] = useState(false);

  function switchLanguage(lng: string) {
    const parsedQuery = queryString.parse(
      window.location.search?.slice(1) ?? ''
    );
    parsedQuery['lng'] = [lng];
    window.location.href =
      window.location.pathname + '?' + queryString.stringify(parsedQuery);
  }

  const menuEntries: MenuEntry[] = [
    {
      label: t('app.navigation.overview'),
      onClick: () => navigate(overviewPath),
    },
    {
      label: t('app.navigation.newItem'),
      onClick: () => navigate(newPath),
    },
    ...ifAdmin(isAdmin ?? false, {
      label: 'Approve (A)',
      onClick: () => navigate(approvalPath),
      rel: 'nofollow',
    }),
    ...ifAdmin(isAdmin ?? false, {
      label: 'Tags (A)',
      onClick: () => navigate(tagsPath),
      rel: 'nofollow',
    }),
    {
      label: t('app.navigation.impressum'),
      onClick: () => navigate(impressumPath),
    },
    ...ifLoggedOut(user, {
      label: t('app.navigation.login'),
      onClick: () => navigate(loginPathForRedirect(location.pathname)),
      rel: 'nofollow',
    }),
    ...ifLoggedIn(user, {
      label: t('app.navigation.logout'),
      onClick: () => auth.signOut(),
      rel: 'nofollow',
    }),
  ];

  return (
    <>
      <Header
        background="light-4"
        pad="xsmall"
        height="xxsmall"
        className="no-print">
        <Link to={overviewPath} style={{ textDecoration: 'none' }}>
          <Text
            color={'brand'}
            weight={'bold'}
            style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {t('app.title')}
          </Text>
        </Link>
        <Box justify="end" direction={'row'} align={'center'}>
          <DropButton
            label={i18n.language.toUpperCase()}
            icon={<FormDown color="brand" size="16px" />}
            gap="xxsmall"
            reverse
            plain
            dropAlign={{ top: 'bottom' }}
            color={'brand'}
            className={styles.languageSelector}
            dropContent={
              <Box direction="column" pad={'small'}>
                <Anchor label={'DE'} onClick={() => switchLanguage('de')} />
                <Anchor label={'EN'} onClick={() => switchLanguage('en')} />
                <Anchor label={'FR'} onClick={() => switchLanguage('fr')} />
                <Anchor label={'TR'} onClick={() => switchLanguage('tr')} />
              </Box>
            }></DropButton>

          {/* <Box
            direction="row"
            className={styles.languageSelector}
            align={'center'}>
            <Anchor label={'DE'} onClick={() => switchLanguage('de')} />
            &nbsp;/&nbsp;
            <Anchor label={'EN'} onClick={() => switchLanguage('en')} />
            &nbsp;/&nbsp;
            <Anchor label={'FR'} onClick={() => switchLanguage('fr')} />
            &nbsp;/&nbsp;
            <Anchor label={'TR'} onClick={() => switchLanguage('tr')} />
          </Box> */}
          <Anchor
            icon={<RiQuestionLine size={14} />}
            onClick={() => {
              setShowHelp(true);
              logEvent(analytics, 'open_help');
            }}
          />
          {showHelp && (
            <Layer
              className={styles.helpPopup}
              onEsc={() => setShowHelp(false)}
              onClickOutside={() => setShowHelp(false)}>
              {React.cloneElement(help, { onClose: () => setShowHelp(false) })}
            </Layer>
          )}
          <ResponsiveContext.Consumer>
            {(size) =>
              size === 'small' ? (
                <Menu
                  margin={{ left: '8px' }}
                  a11yTitle="Navigation Menu"
                  dropProps={{ align: { top: 'bottom', right: 'right' } }}
                  icon={<MenuIcon color="brand" />}
                  items={menuEntries}
                />
              ) : (
                <Box direction="row" gap="medium" margin={{ left: '16px' }}>
                  {menuEntries.map((e) => (
                    <Anchor
                      key={e.label}
                      label={e.label}
                      onClick={e.onClick}
                      rel={e.rel}
                    />
                  ))}
                </Box>
              )
            }
          </ResponsiveContext.Consumer>
        </Box>
      </Header>
      <NotificationBar></NotificationBar>
    </>
  );
}

export default ApplicationHeader;
