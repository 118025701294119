import { Mosaic as MosaicModel } from '../model/mosaic';
import { forwardRef, memo, Ref, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Cube } from '../cube/cube';
import { svgStyles } from '../svg/styles';
import { ItemHandle } from '../common/item/item_handle';
import SvgImgRenderer, {
  RenderType,
} from '../common/svg_image_renderer/svg_img_renderer';
import ApplicationContext from '../common/application/context';
import Application from '../common/application/application';

interface MosaicProps {
  mosaic: MosaicModel;
  onCubeClick?: (x: number, y: number) => void;
  render?: RenderType;
}

function _Mosaic(
  { mosaic, onCubeClick = undefined, render }: MosaicProps,
  ref: Ref<ItemHandle>
) {
  const application = useContext<Application>(ApplicationContext);

  const svgSize = 800;
  const viewBox = `0 0 ${svgSize} ${svgSize}`;
  const strokeWidth = 2;

  const [svgIdPrefix] = useState(uuidv4());

  const cubeSize = (svgSize - strokeWidth) / mosaic.size;
  const cubes = [];
  for (let y = 0; y < mosaic.rows.length; y++) {
    for (let x = 0; x < mosaic.rows[y].length; x++) {
      cubes.push(
        <Cube
          key={`${x},${y}`}
          cube={mosaic.rows[y][x]}
          x={x}
          y={y}
          onCubeClick={onCubeClick}
          cubeSize={cubeSize}
          strokeWidth={strokeWidth}
          svgIdPrefix={svgIdPrefix}
        />
      );
    }
  }

  return (
    <SvgImgRenderer
      ref={ref}
      item={mosaic}
      render={render ?? application.defaultRenderType}
      fileNamePrefix="mosaic"
      viewBox={viewBox}
      width={svgSize}
      height={svgSize}>
      {svgStyles(mosaic.theme, svgIdPrefix)}
      {cubes}
    </SvgImgRenderer>
  );
}

export const Mosaic = memo(forwardRef(_Mosaic));
export default Mosaic;
