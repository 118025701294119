import * as svgAsPng from 'save-svg-as-png';
import { Item } from '../../model/item';
import Renderer, { SvgData } from './renderer';

export default class SvgAsPngRenderer implements Renderer {
  saveAsPng(
    item: Item<any>,
    svg: SVGSVGElement,
    fileName: string,
    scale: number
  ): void {
    svgAsPng.saveSvgAsPng(svg, fileName, { scale: scale });
  }

  toPngUri(
    item: Item<any>,
    svg: SVGSVGElement,
    scale: number
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      svgAsPng.svgAsPngUri(svg, { scale: scale }, (url: string) => {
        resolve(url);
      });
    });
  }

  toSvg(svg: SVGSVGElement, scale: number): Promise<SvgData> {
    return new Promise<SvgData>((resolve, reject) => {
      svgAsPng.svgAsDataUri(
        svg,
        { scale: scale },
        (data: string, width: number, height: number) => {
          return resolve({
            dataUri: data,
            width: width,
            height: height,
          });
        }
      );
    });
  }
}
