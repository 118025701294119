import React from 'react';
import { overviewPath } from '../routes/routes';
import { Box } from 'grommet';
import { Link } from 'react-router-dom';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<any, State> {
  constructor(props: State) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // TODO: translate error message
      return (
        <Box>
          <p>Couldn't find item</p>
          <Link to={overviewPath}>Go back to overview</Link>
        </Box>
      );
    }

    return this.props.children;
  }
}
