import Experiment from '../experiments/experiment';

export default interface UserInfo {
  get isAdmin(): boolean;
  isInExperiment(experiment: Experiment): boolean;
}

class AnonymousUserInfo implements UserInfo {
  get isAdmin(): boolean {
    return false;
  }

  isInExperiment(experiment: Experiment): boolean {
    return false;
  }
}

export const anonymousUserInfo = new AnonymousUserInfo();
