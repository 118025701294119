import { Box, Markdown } from 'grommet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { overviewPath } from '../routes/routes';

function Impressum() {
  const { t } = useTranslation();
  return (
    <Box direction={'column'} pad={'small'}>
      <Link to={overviewPath} style={{ textDecoration: 'none' }}>
        {t('impressum.backLink')}
      </Link>
      <Markdown>{t('impressum.contact')}</Markdown>
      <Markdown>{t('impressum.mail')}</Markdown>
      <Markdown>{t('impressum.privacy')}</Markdown>
    </Box>
  );
}

export default Impressum;
