import { DocumentData } from 'firebase/firestore';
import { Box, Button, DataTable, Heading, Layer, TextInput } from 'grommet';
import { useCallback, useContext, useEffect, useState } from 'react';
import ApplicationContext from '../application/context';
import { FirebaseTag, FirebaseTagService } from '../tag/tag_service';

export default function TagManagement() {
  const application = useContext(ApplicationContext);
  const tagService = application.tagService as FirebaseTagService;

  const [tags, setTags] = useState<EditableTag[]>([]);
  const [selectedTag, setSelectedTag] = useState<EditableTag | undefined>(
    undefined
  );

  const [newTagId, setNewTagId] = useState<string | undefined>(undefined);

  const reloadTags = useCallback(() => {
    tagService
      .getTags()
      .then((tags) =>
        setTags(tags.map((tag) => new EditableTag(tag as FirebaseTag)))
      );
  }, [tagService]);

  useEffect(() => {
    reloadTags();
  }, [reloadTags]);
  const newTagDefaultId = 'new-tag-id';
  return (
    <Box style={{ width: '80vw' }}>
      <Box margin={{ top: 'medium', bottom: 'medium' }} direction="row">
        <Button
          primary={true}
          onClick={() => setNewTagId(newTagDefaultId)}
          label="Create new"></Button>
      </Box>
      {newTagId !== undefined && (
        <Layer>
          <Box pad={'medium'}>
            <Heading level={4}>Add the new tag id (cannot be changed)</Heading>
            <TextInput
              value={newTagId}
              onChange={(e) => setNewTagId(e.target.value)}
            />
            <Box direction="row" gap="small" margin={{ top: 'medium' }}>
              <Button
                primary={true}
                disabled={
                  newTagId === newTagDefaultId ||
                  tags.some((tag) => tag.tag.id === newTagId)
                }
                onClick={() => {
                  tagService.createTag(newTagId).then(() => {
                    setNewTagId(undefined);
                    reloadTags();
                  });
                }}
                label="Create"></Button>
              <Button
                onClick={() => {
                  setNewTagId(undefined);
                }}
                label="Cancel"></Button>
            </Box>
          </Box>
        </Layer>
      )}
      <DataTable
        data={tags}
        columns={[
          {
            property: 'id',
            header: 'ID',
            render: (tag: EditableTag) => tag.tag.id,
          },
          ...[
            'name',
            ...application.languages.map((language) => `name_${language}`),
          ].map((p) => ({
            property: p,
            header: p,
            render: (tag: EditableTag) => (
              <TagPropertyEditor
                tag={tag}
                property={p}
                inEdit={tag === selectedTag}
              />
            ),
          })),
          {
            property: 'actions',
            header: 'Actions',
            render: (tag: EditableTag) =>
              tag === selectedTag ? (
                <Box direction="row" gap="xsmall">
                  <Button
                    plain={true}
                    onClick={(event) => {
                      tagService
                        .updateTag(tag.tag, tag.editedDocumentData)
                        .then(() => {
                          setSelectedTag(undefined);
                          reloadTags();
                          event.stopPropagation();
                        });
                    }}>
                    Save
                  </Button>
                  <Button
                    plain={true}
                    onClick={(event) => {
                      setSelectedTag(undefined);
                      event.stopPropagation();
                    }}>
                    Cancel
                  </Button>
                  <Button
                    plain={true}
                    onClick={(event) => {
                      tagService.deleteTag(tag.tag).then(() => {
                        setSelectedTag(undefined);
                        reloadTags();
                        event.stopPropagation();
                      });
                    }}>
                    Delete
                  </Button>
                </Box>
              ) : (
                ''
              ),
          },
        ]}
        onClickRow={(event) => {
          if (selectedTag === undefined) {
            setSelectedTag(event.datum);
          }
        }}
      />
    </Box>
  );
}

class EditableTag {
  editedDocumentData: DocumentData;
  constructor(readonly tag: FirebaseTag) {
    this.editedDocumentData = { ...tag.documentData };
  }

  reset() {
    this.editedDocumentData = { ...this.tag.documentData };
  }
}

function TagPropertyEditor({
  tag,
  property,
  inEdit,
}: {
  tag: EditableTag;
  property: string;
  inEdit: boolean;
}) {
  if (!inEdit) {
    return tag.tag.documentData[property];
  } else {
    return (
      <TextInput
        size="small"
        defaultValue={tag.editedDocumentData[property]}
        onChange={(event) =>
          (tag.editedDocumentData[property] = event.target.value)
        }
      />
    );
  }
}
