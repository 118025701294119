import { Box, SelectMultiple } from 'grommet';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationContext from '../application/context';
import Tag from './tag';
import TagsList from './tags_list';

interface TagSelectProps {
  values: Set<Tag>;
  onChange: (values: Set<Tag>) => void;
}

export default function TagSelect({ values, onChange }: TagSelectProps) {
  const [tags, setTags] = useState<Tag[]>([]);
  const { t, i18n } = useTranslation();
  const application = useContext(ApplicationContext);
  const tagService = application.tagService;

  useEffect(() => {
    tagService.getTags(i18n.languages).then((tags) => setTags(tags));
  }, [tagService, i18n.languages]);

  return (
    <SelectMultiple
      valueLabel={(values) =>
        values.length === 0 ? (
          <Box>{t('editor.tags.placeholder')}</Box>
        ) : (
          <TagsList tags={values}></TagsList>
        )
      }
      dropHeight={'medium'}
      dropAlign={{ bottom: 'top' }}
      multiple={true}
      limit={3}
      onSearch={(search) =>
        tagService
          .searchTags(search, i18n.languages)
          .then((tags) => setTags(tags))
      }
      plain={true}
      options={tags}
      value={Array.from(values)}
      labelKey={(tag: Tag) => tag.getName(i18n.languages)}
      onChange={({ value, option }) => onChange(value)}></SelectMultiple>
  );
}
