import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { viewPathForItem } from '../routes/routes';
import React from 'react';
import ItemMeta from '../item_meta/item_meta';
import { Item } from '../model/item';

interface OverviewCardProps<T extends Item<T>>
  extends React.PropsWithChildren<{}> {
  item: T;
}

function OverviewCard<T extends Item<T>>({
  item,
  children,
}: OverviewCardProps<T>) {
  return (
    <div
      className={styles.container}
      key={item.id}
      data-testid={'c-overview-card'}>
      <div className={styles.item}>
        <Link to={viewPathForItem(item.id!)}>{children}</Link>
      </div>
      <div className={styles.meta}>
        <ItemMeta item={item} />
      </div>
    </div>
  );
}

export default OverviewCard;
