import { Accordion, AccordionPanel, Box, Button, Text } from 'grommet';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseLine } from 'react-icons/ri';

interface HelpProps {
  onClose?: () => void;
}

function Help(props: HelpProps) {
  const { t } = useTranslation();
  return (
    <Box direction={'column'}>
      <Button
        icon={<RiCloseLine size={32} />}
        onClick={(_) => props.onClose?.()}
        alignSelf={'end'}
      />
      <Accordion margin={{ left: 'medium', right: 'medium' }}>
        <AccordionPanel label={t('help.introduction.title')}>
          <Box margin={{ top: 'medium', bottom: 'medium' }}>
            <Text>{t('help.introduction.text.1')}</Text>
            <Text margin={{ top: 'medium' }}>
              {t('help.introduction.text.2')}
            </Text>
          </Box>
        </AccordionPanel>
        <AccordionPanel label={t('help.overview.title')}>
          <Box margin={{ top: 'medium', bottom: 'medium' }}>
            <Text>{t('help.overview.text.1')}</Text>
            <Text margin={{ top: 'medium' }}>{t('help.overview.text.2')}</Text>
          </Box>
        </AccordionPanel>
        <AccordionPanel label={t('help.view.title')}>
          <Box margin={{ top: 'medium', bottom: 'medium' }}>
            <Text>{t('help.view.text.1')}</Text>
            <Text margin={{ top: 'medium' }}>{t('help.view.text.2')}</Text>
          </Box>
        </AccordionPanel>
        <AccordionPanel label={t('help.edit.title')}>
          <Box margin={{ top: 'medium', bottom: 'medium' }}>
            <Text>{t('help.edit.text.1')}</Text>
            <Text margin={{ top: 'medium' }}>{t('help.edit.text.2')}</Text>
            <Text margin={{ top: 'medium' }}>{t('help.edit.text.3')}</Text>
            <Text margin={{ top: 'medium' }}>{t('help.edit.text.4')}</Text>
          </Box>
        </AccordionPanel>
      </Accordion>
    </Box>
  );
}

export default Help;
