import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { Box, Button, Layer } from 'grommet';
import { useState } from 'react';
import { RiShareLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'reactfire';
import { logEvent } from 'firebase/analytics';

interface ShareProps {
  iconSize?: number;
  disabled?: boolean;
  eventData: {
    [key: string]: any;
  };
}

function Share(
  props: ShareProps = {
    iconSize: 32,
    disabled: false,
    eventData: {},
  }
) {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const location = useLocation();
  const analytics = useAnalytics();

  let url = window.location.origin;

  if (
    location.pathname.startsWith('/overview') ||
    location.pathname.startsWith('/view')
  ) {
    url = url + location.pathname;
  }

  const shareIconSize = 32;
  return (
    <>
      <Button
        icon={<RiShareLine size={props.iconSize} />}
        onClick={(_) => {
          setPopupOpen(true);
          logEvent(analytics, 'open_share_popup_click', props.eventData);
        }}
        disabled={props.disabled}
      />

      {popupOpen && (
        <Layer
          onClickOutside={(_) => setPopupOpen(false)}
          onEsc={(_) => setPopupOpen(false)}
          responsive={false}>
          <Box direction={'row'} margin={'medium'} gap={'small'}>
            <EmailShareButton
              url={url}
              beforeOnClick={() =>
                logEvent(analytics, 'share_click', {
                  ...props.eventData,
                  channel: 'email',
                })
              }>
              <EmailIcon size={shareIconSize} />
            </EmailShareButton>
            <FacebookShareButton
              url={url}
              beforeOnClick={() =>
                logEvent(analytics, 'share_click', {
                  ...props.eventData,
                  channel: 'facebook',
                })
              }>
              <FacebookIcon size={shareIconSize} />
            </FacebookShareButton>
            <TwitterShareButton
              url={url}
              beforeOnClick={() =>
                logEvent(analytics, 'share_click', {
                  ...props.eventData,
                  channel: 'twitter',
                })
              }>
              <TwitterIcon size={shareIconSize} />
            </TwitterShareButton>
            <WhatsappShareButton
              url={url}
              beforeOnClick={() =>
                logEvent(analytics, 'share_click', {
                  ...props.eventData,
                  channel: 'whatsapp',
                })
              }>
              <WhatsappIcon size={shareIconSize} />
            </WhatsappShareButton>
          </Box>
        </Layer>
      )}
    </>
  );
}

export default Share;
