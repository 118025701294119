export class Pattern {
  static readonly empty = new Pattern('E');
  static readonly solid1 = new Pattern('S1');
  static readonly solid2 = new Pattern('S2');
  static readonly solid3 = new Pattern('S3');
  static readonly solid4 = new Pattern('S4');
  static readonly diag1 = new Pattern('D1');
  static readonly diag2 = new Pattern('D2');
  static readonly values = [
    Pattern.solid1,
    Pattern.solid2,
    Pattern.solid3,
    Pattern.solid4,
    Pattern.diag1,
    Pattern.diag2,
    Pattern.empty,
  ];
  static readonly solidPatterns = Pattern.values.filter((p) => p.isSolid);
  static readonly diagPatterns = Pattern.values.filter((p) => !p.isSolid);
  readonly id: string;

  constructor(id: string) {
    this.id = id;
  }

  get isSolid() {
    return this.id !== Pattern.diag1.id && this.id !== Pattern.diag2.id;
  }

  toString() {
    return this.id;
  }

  equals(other: Pattern) {
    if (!other) return false;
    return this.id === other.id;
  }
}

export class Cube {
  static readonly empty = new Cube(Pattern.empty, 0);
  readonly pattern: Pattern;
  readonly rotation: number;

  constructor(pattern: Pattern, rotation: number) {
    this.pattern = pattern;
    this.rotation = rotation;
  }

  get isEmpty() {
    return this.pattern.equals(Pattern.empty);
  }

  rotateCube(delta: number): Cube {
    return new Cube(this.pattern, (this.rotation + delta) % 4);
  }

  rotateLeft() {
    return this.rotateCube(-1);
  }

  rotateRight() {
    return this.rotateCube(1);
  }

  rotateHalf() {
    return this.rotateCube(2);
  }

  mirrorHorizontally() {
    return this.rotation % 2 === 0 ? this.rotateRight() : this.rotateLeft();
  }

  mirrorVertically() {
    return this.rotation % 2 === 0 ? this.rotateLeft() : this.rotateRight();
  }

  toString() {
    return this.pattern.toString();
  }

  equals(other: Cube): boolean {
    if (!other) {
      return false;
    }
    return (
      this.pattern.equals(other.pattern) &&
      (this.pattern.isSolid || this.rotation % 4 === other.rotation % 4)
    );
  }
}
