import { useEffect } from 'react';
import { useAnalytics } from 'reactfire';
import { useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

export function PageViewLogger() {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_location: location.pathname });
  }, [location.pathname, analytics]);
  return null;
}
