import { Box, Button } from 'grommet';
import { useContext, useEffect, useState } from 'react';
import { useAnalytics, useUser } from 'reactfire';
import style from './style.module.scss';

import { logEvent } from 'firebase/analytics';
import { RiHeartFill, RiHeartLine } from 'react-icons/ri';
import ApplicationContext from '../application/context';
import { Item } from '../model/item';
import TagsList from '../tag/tags_list';

interface ItemMetaProps<T extends Item<T>> {
  item: T;
}

function ItemMeta<T extends Item<T>>({ item }: ItemMetaProps<T>) {
  const [likeHovering, setLikeHovering] = useState(false);
  // Local state so we can temporary update the likes until the item
  // gets updated through triggers
  const [currentItem, setCurrentItem] = useState(item);
  const { data: user } = useUser();
  const analytics = useAnalytics();
  const application = useContext(ApplicationContext);
  const itemService = application.itemService;

  useEffect(() => {
    setCurrentItem(item);
  }, [item]);

  async function likeItem() {
    await itemService.likeItem(currentItem, user!);
    setCurrentItem(await currentItem.addLiker(user!));
    setLikeHovering(false);
    logEvent(analytics, 'like_item_click', {
      item_type: item.type,
      item_id: item.id,
    });
  }

  async function unlikeItem() {
    await itemService.unlikeItem(currentItem, user!);
    setCurrentItem(await currentItem.removeLiker(user!));
    setLikeHovering(false);
    logEvent(analytics, 'unlike_item_click', {
      item_type: item.type,
      item_id: item.id,
    });
  }

  let likeControl;
  if (!user) {
    likeControl = <RiHeartLine />;
  } else {
    if (currentItem.likers.has(user.uid)) {
      const icon = likeHovering ? <RiHeartLine /> : <RiHeartFill />;
      likeControl = (
        <Button
          plain
          icon={icon}
          onMouseEnter={() => setLikeHovering(true)}
          onMouseLeave={() => setLikeHovering(false)}
          onClick={unlikeItem}
        />
      );
    } else {
      const icon = likeHovering ? <RiHeartFill /> : <RiHeartLine />;
      likeControl = (
        <Button
          plain
          icon={icon}
          onMouseEnter={() => setLikeHovering(true)}
          onMouseLeave={() => setLikeHovering(false)}
          onClick={likeItem}
        />
      );
    }
  }

  return (
    <Box direction={'row'} align="start" className={`${style.meta} no-print`}>
      <Box direction={'row'} pad={{ top: 'xxsmall' }}>
        {likeControl}
        <div className={style.count}>{currentItem.likers.size}</div>
      </Box>
      <Box margin={{ left: 'auto' }} pad={{ left: 'small' }}>
        <TagsList tags={currentItem.tags}></TagsList>
      </Box>
    </Box>
  );
}

export default ItemMeta;
