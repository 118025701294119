import { Box, Tag as TagComponent } from 'grommet';
import { useTranslation } from 'react-i18next';
import Tag from './tag';
import { sortTags } from './tag_service';

interface TagsListProps {
  tags: Iterable<Tag>;
}

export default function TagsList({ tags }: TagsListProps) {
  const { i18n } = useTranslation();
  const sortedTags = sortTags(tags, i18n.languages);
  return (
    <Box
      direction="row"
      gap={'xsmall'}
      style={{ rowGap: 4, flexWrap: 'wrap', justifyContent: 'flex-end' }}>
      {sortedTags
        .filter((t) => t !== undefined)
        .map((v: Tag) => (
          <TagComponent
            value={v.getName(i18n.languages)}
            size={'small'}
            key={v.id}></TagComponent>
        ))}
    </Box>
  );
}
