import queryString from 'query-string';
import { generatePath, useLocation, useParams } from 'react-router-dom';

export const overviewPath = '/overview';

export const viewPath = '/view/:id';

export type ViewPathParams = {
  id: string;
};

export function viewPathForItem(itemId: string) {
  return generatePath(viewPath, { id: itemId });
}

export function useViewPathParams() {
  return useParams<ViewPathParams>();
}

export const editPath = '/edit/:id/:action?';

export type EditPathParams = {
  id: string;
  action: 'copy' | undefined;
};

export function editPathForItem(itemId: string) {
  return generatePath(editPath, { id: itemId, action: null });
}

export function copyPathForItem(itemId: string) {
  return generatePath(editPath, { id: itemId, action: 'copy' });
}

export function useEditPathParams() {
  return useParams<EditPathParams>();
}

export const newPath = '/edit';

export const loginPath = '/login';

export function loginPathForRedirect(redirect: string) {
  return (
    generatePath(loginPath) +
    '?' +
    queryString.stringify({ redirect: redirect })
  );
}

export function useRedirectQueryParameter() {
  const location = useLocation();
  const queryParams = queryString.parse(location.search?.slice(1) ?? '');
  return queryParams['redirect'] as string;
}

export const approvalPath = '/admin/approval';
export const tagsPath = '/admin/tags';

export const impressumPath = '/impressum';
