import * as React from 'react';
import { useFirebaseApp } from 'reactfire';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

export interface FirebaseEmulatorConfigProps {
  children: React.ReactNode;
}

declare global {
  var areEmulatorsInitialized: boolean;
}

function FirebaseEmulatorConfig({ children }: FirebaseEmulatorConfigProps) {
  const app = useFirebaseApp();

  const useEmulators = process.env.REACT_APP_FIREBASE_USE_EMULATORS === 'true';
  if (useEmulators && !global.areEmulatorsInitialized) {
    connectAuthEmulator(getAuth(app), 'http://localhost:9099/');
    connectFirestoreEmulator(getFirestore(app), 'localhost', 8080);
    connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
    global.areEmulatorsInitialized = true;
  }

  return <>{children}</>;
}

export default FirebaseEmulatorConfig;
