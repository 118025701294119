const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
    colors: {
      brand: 'rgb(0,128,96)',
    },
  },
  anchor: {
    fontWeight: 400,
  },
  button: {
    border: {
      radius: '4px',
    },
  },
};

export default theme;
