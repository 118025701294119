import React from 'react';
import {
  AnalyticsProvider,
  AuthProvider,
  FirestoreProvider,
  useFirebaseApp,
} from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

export function FirebaseContextProviders(props: React.PropsWithChildren<{}>) {
  const firebaseApp = useFirebaseApp();
  const firestoreSdk = getFirestore(firebaseApp);
  const firebaseAuthSdk = getAuth(firebaseApp);
  const analytics = getAnalytics(firebaseApp);

  return (
    <AnalyticsProvider sdk={analytics}>
      <FirestoreProvider sdk={firestoreSdk}>
        <AuthProvider sdk={firebaseAuthSdk}>{props.children}</AuthProvider>
      </FirestoreProvider>
    </AnalyticsProvider>
  );
}
