import { User } from 'firebase/auth';
import Tag from '../tag/tag';

export abstract class Item<T extends Item<T>> {
  readonly public: boolean;

  constructor(
    readonly type: string,
    readonly id: string | undefined,
    readonly uid: string | undefined,
    isPublic: boolean = false,
    readonly approved: boolean,
    readonly likers: Set<String>,
    readonly tags: Set<Tag>
  ) {
    this.public = isPublic;
  }

  get numLikes(): number {
    return this.likers.size;
  }

  isEditableBy(uid: string): boolean {
    return uid != null && this.uid === uid;
  }

  isLikedBy(uid: string): boolean {
    return this.likers.has(uid);
  }

  abstract get isComplete(): boolean;

  abstract setPublic(isPublic: boolean): T;

  abstract setApproved(approved: boolean): T;

  abstract addLiker(user: User): Promise<T>;

  abstract removeLiker(user: User): Promise<T>;

  abstract setTags(tags: Set<Tag>): T;

  abstract copyForCurrentUser(uid: string): T;
}
